<template>
    <GeneralTermsLayout>
        <div ref="top" id="top"></div>
        <div ref="main" class="row" v-if="status ==='READING_LICENSE' || status === 'READING_ATTACHMENT_A'">
            <div class="col-12" v-if="status === 'READING_LICENSE'">
                <h1 class="text-center">Accordo n. S{{ store.id }} del {{ store.license_activation_date }} </h1>
                <h5 class="text-center">tra</h5>
                <p>
                    <b>Vysio S.r.l.</b> (C.F/P.IVA 05156040759), in persona del legale rappresentante pro tempore, con sede legale in Casarano (LE) alla Via Carlo Magno n. 17 (d’ora in avanti anche il “<b>Fornitore</b>”)   
                </p>
                <h5 class="text-center">e</h5>
                <p>
                    <b>{{ store.company_name }}</b> (C.F/P.IVA {{ store.company_id }}), in persona del legale rappresentante pro tempore, con sede legale in {{ store.company_city }} alla Via {{ store.company_address }} (d’ora in avanti anche il “<b>Cliente</b>”) 
                </p>
                <p>
                    (di seguito singolarmente “<b>Parte</b>” e congiuntamente “<b>Parti</b>”)
                </p>
                <h5 class="text-center py-3">Premesso che</h5>
                <ol type="a">
                    <li>
                        il Fornitore è una società start-up innovativa attiva nell’ambito dello sviluppo di software per il settore ottico-optometristico; 
                    </li>
                    <li>
                        il Cliente è una società che si occupa, tra l’altro, di servizi optometrici per l’approntamento di occhiali con lenti progressive alle persone; 
                    </li>
                    <li>
                        il Fornitore ha sviluppato un software di aiuto all’adattamento degli utenti all’utilizzo di lenti oftalmiche progressive; 
                    </li>
                    <li>
                        le Parti hanno sottoscritto un separato accordo di licenza di cui al separato allegato al presente accordo (d’ora in avanti anche “<b>Allegato A</b>”) 
                        mediante il quale sono stati concessi al Cliente i diritti d’uso sul software “Adapta Easy” su tutti i moduli e aggiornamenti dello stesso 
                        realizzati sino al 31 dicembre 2025; 
                    </li>
                    <li>
                        il Cliente intende acquistare dal Fornitore - che accetta - i dispositivi di utilizzo del software di cui alle precedenti premesse (l’<b>hardware</b>), 
                        nonché commissionare i servizi di manutenzione di detti dispositivi e del software stesso, supporto richieste saltuarie, supporto operativo 
                        e help desk di tutti quanti i software in uso attualmente e/o che lo saranno in futuro presso il Cliente e ricompresi nel contratto di 
                        licenza di cui all’Allegato A del presente accordo.
                    </li>
                </ol>
                <h5 class="text-center my-2 py-4">
                    Si conviene e si stipula quanto segue:
                </h5>
                <h4 class="text-center my-2">
                    Articolo 1. Premesse e definizioni
                </h4>
                <p>
                    Le premesse e gli allegati sono da considerarsi parte integrante del presente accordo. <br/>
                    Le Parti concordano espressamente che, in aggiunta ai termini eventualmente definiti in altre clausole del presente accordo, le espressioni qui di seguito elencate hanno il significato specificato a fianco di ciascuna di esse. <br/>
                    <b>Accordo</b>. Indica il presente documento contrattuale comprensivo dei relativi Allegati.  <br/>
                    <b>Allegati</b>. Indica gli allegati all’Accordo, che ne costituiscono parte integrante e sostanziale. <br/>
                    <b>Contratto di Licenza</b>. Indica il contratto di licenza che il Cliente sottoscriverà all’atto di accensione dell’Hardware e di installazione del Software Adapta Easy, qui presente nell’<b>Allegato A</b>. <br/>
                    <b>Software</b>. Indica il software <b>Adapta Easy</b>, di cui al brevetto n. 202022000004275 di titolarità del Fornitore, il quale consente agli utilizzatori di fornire procedure optometriche finalizzate all’adattamento degli utenti alle lenti visive progressive. <br/>
                    <b>Hardware</b>. Indica dispositivi acquistati dal Cliente per l’installazione del Software, i quali comprendono: <br/>
                </p>
                <ol type="1">
                    <li>
                        postazione fissa con schermo touchscreen 15,6 pollici per l’utilizzo da vicino del paziente e cassetto contenitore degli strumenti;
                    </li>
                    <li>
                        totem in plexiglass con schermo touchscreen 32 pollici;
                    </li>
                    <li>
                        strumenti specifici per l’esecuzione delle procedure optometriche;
                    </li>
                    <li>
                        scheda madre di sistema.
                    </li>
                </ol>
                <p>
                    <b>Servizi</b>. Indica le attività di manutenzione, supporto richieste saltuarie, supporto operativo e help desk che sono state 
                    svolte e/o verranno svolte dal Fornitore sul Software. <br/>
                    <b>Responsabile del Cliente</b>. Indica il responsabile dei Servizi per il Cliente. <br/>
                    <b>Responsabile del Fornitore</b>. Indica il responsabile dei Servizi per il Fornitore. <br/>
                </p>
                <h4 class="text-center my-2">
                    Articolo 2 - Oggetto
                </h4>
                <ol type="1">
                    <li>
                        Le Parti, con l’Accordo, intendono definire la vendita dell’Hardware e le modalità d’uso del Software in esso 
                        installato e le modalità di esecuzione ed erogazione dei Servizi sull’Hardware e sul Sofware.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 3 - Hardware
                </h4>
                <ol type="1">
                    <li>
                        Con il presente Accordo il Cliente acquista dal Fornitore l’Hardware, comprensivo dei seguenti materiali e strumenti: 
                        <ol type="a">
                            <li>
                                postazione fissa con schermo touchscreen 15,6 pollici per l’utilizzo da vicino del paziente e cassetto contenitore degli strumenti;
                            </li>
                            <li>
                                totem in plexiglass con schermo touchscreen 32 pollici;
                            </li>
                            <li>
                                strumenti specifici di visita del paziente;
                            </li>
                            <li>
                                scheda madre di sistema.
                            </li>
                        </ol>
                    </li>
                    <li>
                        L’Hardware viene acquistato al prezzo di cui al successivo articolo 5 (corrispettivo) e, al momento della sottoscrizione dell’Accordo, 
                        il Cliente dichiara di aver già ricevuto l’Hardware presso la sede indicata in fase di acquisto diretto.
                    </li>
                    <li>
                        Il Fornitore fornisce le garanzie di legge sui beni componenti dell’Hardware. In particolare, il Fornitore garantisce assistenza e manutenzione gratuita sull’Hardware per il periodo di 2 (due) anni dalla consegna del medesimo al Cliente, 
                        compresa l’eventuale sostituzione in caso di malfunzionamento irreversibile ovvero di inidoneità all’uso pattuito.
                    </li>
                    <li>
                        Il Cliente dovrà denunciare eventuali vizi ed anomalie dell’Hardware entro 8 (otto) giorni dal ricevimento del medesimo presso la propria sede.
                    </li>
                    <li>
                        Il Cliente dà atto con la sottoscrizione del presente Accordo che l’Hardware ricevuto è perfettamente funzionante ed idoneo allo scopo.
                    </li>
                    <li>
                        Il Cliente, all’acquisto dell’Hardware, ne diviene esclusivo proprietario e responsabile del corretto utilizzo e funzionamento, salve le garanzie di legge e di cui al presente Accordo. Il Cliente si impegna comunque a non utilizzare l’Hardware per scopi 
                        diversi dall’utilizzo del Software almeno per tutta la durata della licenza d’uso del Software (Allegato A). 
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 4 - Prestazione dei Servizi
                </h4>
                <ol type="1">
                    <li>
                        Il Cliente acquista dal Fornitore l’Hardware per l’utilizzo del Software concesso in uso dal 
                        Fornitore al Cliente secondo quanto stabilito alla relativa licenza di cui all’Allegato A.
                    </li>
                    <li>
                        Il Cliente affida al Fornitore, che accetta, il compito di gestire in piena autonomia i Servizi meglio definiti al successivo punto 
                        4.5 connessi al funzionamento dell’Hardware e con le modalità di esecuzione qui di seguito indicate.  
                    </li>
                    <li>
                        Il Fornitore si impegna, pertanto, ad eseguire i Servizi individuati nei successivi 
                        punti 4.5 e 4.6 con diligenza e professionalità, sempre avvalendosi di personale specializzato. 
                    </li>
                    <li>
                        I Servizi sono erogati da parte del Fornitore a mezzo telefonico ovvero, in caso di necessità ed a spese del Cliente, 
                        presso la sede di quest’ultimo, dalle 10.00 alle 18.00 dal lunedì al venerdì nei giorni non festivi.
                    </li>
                    <li>
                        Il Fornitore si impegna, pertanto, a prestare i Servizi di Manutenzione Evolutiva, Adattativa e di Supporto Richieste Saltuarie. Nello specifico:
                        <ol type="a">
                            <li>
                                Per Manutenzione Evolutiva si intende l’insieme degli interventi di modifica al Software e finalizzati al miglioramento o all’adeguamento delle funzionalità unilateralmente 
                                predisposti dal Fornitore in caso di esigenze tecniche ovvero di mercato di quest’ultimo. 
                            </li>
                            <li>
                                Per Manutenzione Adattattiva si intendono gli interventi di modifica al Software originati da specifiche richieste di Enti Istituzionali di Controllo o da adeguamenti richiesti da normative, 
                                che dovranno essere rese disponibili all’uso entro le scadenze specificate da tali Enti o dalle normative. 
                            </li>
                            <li>
                                Per Supporto Richieste Saltuarie si intende la realizzazione di funzioni aggiuntive al Software, di carattere temporaneo, 
                                richieste dal Cliente e legate ad evoluzioni del business, per soddisfare specifici interessi in relazione a nuove 
                                indagini/ricerche di informazioni. Tali realizzazioni devono essere conformi ad uno specifico processo che, in funzione della loro 
                                caratteristica di temporaneità, ne garantisce la separazione dalle altre attività. Le richieste saltuarie di funzioni aggiuntive 
                                sono indirizzate al Fornitore e sono, comunque, soggette ad approvazione preventiva da parte del Fornitore stesso, 
                                sulla base delle stime di impegno derivanti e dei costi, i quali dovranno essere esposti dal Fornitore al Cliente e da questi 
                                accettati e sostenuti. Qualora le richieste siano generate da eventi legati ad anomalie originate dal Software, le attività in oggetto 
                                rientreranno nella Manutenzione Correttiva, tra cui, ad esempio:
                                <ol type="i">
                                    <li>
                                        l’analisi, il chiarimento e l’eventuale intervento di sistemazione di anomalie riscontrate sui flussi generati dai Software;
                                    </li>
                                    <li>
                                        la verifica ed eventuale aggiornamento delle tabelle parametriche dei Software.
                                    </li>
                                </ol>
                                Inoltre, nel Supporto Richieste Saltuarie rientra l’assistenza che il Fornitore si obbliga a fornire al Cliente 
                                rispetto ad eventuali anomali e malfunzionamenti dell’Hardware.
                            </li>
                        </ol>
                            I suddetti Servizi di Manutenzione Evolutiva, Adattativa e di Supporto Richieste Saltuarie si riferiscono esclusivamente ad 
                        interventi di “migliorie minori” per le quali la stima di impegno sia non superiore a 2 (due) giorni. Pertanto, gli interventi 
                        che risultino superiori a 2 (due) giorni, saranno oggetto di un separato accordo tra le Parti. Resta inteso che gli interventi 
                        di Manutenzione Evolutiva, Adattativa e di Supporto richieste saltuarie non potranno comunque superare i 20 (venti) giorni 
                        complessivi per anno solare. Eventuali ulteriori giornate saranno oggetto di un separato accordo tra le Parti.
                    </li>
                </ol>
                <ol type="1" start="6">
                    <li>
                        Il Fornitore si impegna inoltre a prestare il Supporto Operativo, consistente in: 
                        <ol type="a">
                            <li>
                                Manutenzione correttiva. Per Manutenzione Correttiva si intendono gli interventi volti a identificare e correggere, 
                                anche con soluzioni temporanee, malfunzionamenti nella logica e nel funzionamento del Software che si verifichino 
                                dopo l’installazione e l’avvio del Cliente. Eccezionalmente, in caso di comprovata impossibilità ad identificare una correzione, 
                                anche temporanea, il Fornitore si impegna ad attivare un processo di escalation per una valutazione congiunta con il Cliente 
                                circa le attività da intraprendere. Gli interventi di Manutenzione Correttiva, in particolare, comprendono: 
                                <ol type="i">
                                    <li>
                                        interventi di emergenza a fronte di interruzioni dei Servizi al fine di ripristinare l’operatività;
                                    </li>
                                    <li>
                                        correzione del codice; 
                                    </li>
                                    <li>
                                        supporto alla correzione delle procedure batch in esercizio; 
                                    </li>
                                    <li>
                                        supporto al ripristino in esercizio di database corrotti, a seguito di malfunzionamenti verificatisi nel Software sino a pervenire all’integrità del dato; 
                                    </li>
                                    <li>
                                        sistemazione di anomalie, a seguito di malfunzionamenti verificatesi nei Software; 
                                    </li>
                                    <li>
                                        attività necessarie a correggere i malfunzionamenti, ed in particolare, volti a (i) determinare la causa del malfunzionamento (ii) analisi/realizzazione/test di eventuali azioni correttive provvisorie (iii) analisi/realizzazione/test di azioni correttive definitive (iv) supporto all’effettuazione dei test di accettazione (v) effettuazione di tutte le attività necessarie al rilascio in produzione dell’azione correttiva (vi) eventuale modifica di documentazione tecnica. 
                                    </li>
                                </ol>
                                Resta inteso che qualora siano necessari interventi che richiedano accessi per la modifica della base dei dati per correggere situazioni anomale oppure per modificare le procedure batch che abbiano rilevanza sull’erogazione dei Servizi, questi dovranno essere sempre e comunque autorizzati dal Responsabile del Cliente, 
                                individuato nel rappresentante legale ovvero in altro addetto da quest’ultimo comunicato. Gli interventi di Manutenzione Correttiva dovranno essere classificati per priorità indicata dal Cliente e confermata dal Fornitore in funzione dell’importanza delle funzioni (ovvero la misura delle criticità d’ogni singola 
                                funzione applicativa rispetto all’impatto sul business del Cliente) e dell’impatto utente finale (ovvero la classificazione dei malfunzionamenti in funzione della percezione dell’utente finale). 
                                In particolare, si distinguono due livelli di priorità (i) correttiva urgente, che richiede un intervento da parte del Fornitore urgente ed immediato per ripristinare le interruzioni gravi dell’operatività (ii) correttiva pianificabile, che richiede un intervento pianificabile.
                            </li>
                            <li>
                                Supporto Tecnico. Tale Servizio si articola in attività di supporto a: 
                                <ol type="i" start="7">
                                    <li>
                                        recovery/restart del Software e dei dati. In particolare si tratta di fornire supporto per (i) il ripristino delle funzionalità operative, sia online che batch (ii) il recupero gestionale e contabile di situazioni pregresse (database corrotti). Se tale recupero sarà necessario a causa di malfunzionamenti del Software, 
                                        si farà riferimento ad interventi nell’ambito della Manutenzione Correttiva. 
                                    </li>
                                    <li>
                                        gestione della configurazione del Software, intesa come l’insieme degli oggetti (es. programmi, tabelle, strutture dati) che determinano il funzionamento del Software stesso; 
                                    </li>
                                    <li>
                                        aggiornamento della documentazione relativa alla gestione operativa. 
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Help Desk. Tale Servizio fornisce l’interfaccia verso le funzioni del Cliente per: 
                                <ol type="i" start="10">
                                    <li>
                                        l’acquisizione delle richieste di servizio, sia relative alla segnalazione di malfunzionamenti sia a Manutenzioni Evolutive/Adattative; 
                                    </li>
                                    <li>
                                        il controllo dello stato delle richieste e per il relativo aggiornamento verso la funzione richiedente;
                                    </li>
                                    <li>
                                        richieste di informazione di carattere funzionale.
                                    </li>
                                </ol>
                                Le richieste di servizio sono rivolte dal Cliente verso la struttura di Help Desk del Fornitore. <br/>
                                L’erogazione del Servizio avviene secondo le seguenti modalità: 
                                <ol type="i" start="13">
                                    <li>
                                        acquisizione e gestione delle richieste di servizio di pertinenza del Fornitore;
                                    </li>
                                    <li>
                                        analisi della causa del malfunzionamento sulla base delle priorità segnalate; 
                                    </li>
                                    <li>
                                        passaggio alla propria struttura per gli interventi necessari; 
                                    </li>
                                    <li>
                                        controllo ed aggiornamento dello stato delle richieste.
                                    </li>
                                </ol>
                                Il servizio di Help Desk comprende: 
                                <ol type="i" start="17">
                                    <li>
                                        acquisizione e gestione dei problemi di natura tecnologica (quali, ad esempio, malfunzionamenti hardware); 
                                    </li>
                                    <li>
                                        risposte e domande di carattere normativo e funzionale riguardanti l’applicazione delle normative emanate dagli organi di controllo interni o delle regole aziendali della Committente, 
                                        ad eccezione di quanto concerne la normativa di cui al Regolamento UE n. 679/2016 (GDPR).
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Possono emettere richieste dei Servizi di cui al presente articolo 4 il rappresentante legale del Cliente ed ogni suo eventuale incaricato e/o delegato, previamente indicato dal Cliente medesimo. Le comunicazioni tra il Cliente ed il Fornitore (segnalazione, comunicazione di presa in carico, stato intervento, 
                        data prevista di completamento intervento) avvengono a mezzo e-mail agli indirizzi comunicati dalle Parti. 
                    </li>
                    <li>
                        Il Fornitore nomina quale proprio Responsabile il Sig. Marco Seclì, che avrà l’onere di comunicare per iscritto al Cliente, il quale si occuperà dei Servizi e che sarà altresì autorizzato ad avere accesso nella sede del Cliente nonché sui sistemi informatici di titolarità di quest’ultima. Nel caso in cui il Fornitore avesse bisogno di servirsi di altri soggetti diversi da quelli specificamente autorizzati dal Cliente, dovrà comunicarlo a quest’ultimo ed ottenere apposita autorizzazione dal medesimo. 
                        Il Responsabile del Fornitore potrà interfacciarsi con il Responsabile del Cliente nonché con l’ulteriore personale nominato da quest’ultimo per seguire i Servizi.
                    </li>
                    <li>
                        Il Cliente, al fine di permettere la corretta erogazione dei Servizi, si impegna a:
                        <ol type="a">
                            <li>
                                mettere a disposizione del Fornitore tutte quelle informazioni e/o documentazione ed a porre in essere tutti quei comportamenti necessari al fine di 
                                consentire e/o agevolare il Fornitore nell’erogazione dei Servizi;   
                            </li>
                            <li>
                                garantire l’accesso, previo accordo tra le Parti, presso la propria sede ovvero presso i sistemi informatici di cui possiede la titolarità, esclusivamente al Responsabile del Fornitore 
                                nonché al personale specificamente autorizzato dal Cliente; 
                            </li>
                            <li>
                                nominare un proprio Responsabile da comunicarsi all’attivazione del Software, il quale potrà servirsi di ulteriore personale e potrà altresì interfacciarsi con il 
                                Responsabile del Fornitore e con il personale autorizzato del Fornitore. 
                            </li>
                        </ol>
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 5 - Corrispettivo
                </h4>
                <ol type="1">
                    <li>
                        Le Parti si danno atto che per l’acquisto dell’Hardware la concessione in uso del Software e l’erogazione dei Servizi la Committente ha versato al 
                        Fornitore alla data di sottoscrizione dell’Accordo, le seguenti somme:
                        <ol type="a">
                            <li>
                                l’importo di Euro 5.800,00 (cinquemilaottocento/00) oltre IVA per l’acquisto dell’Hardware;
                            </li>
                            <li>
                                l’importo di Euro 3.000,00 (tremila/00) oltre IVA per la concessione in uso del Software e per l’erogazione dei Servizi di cui al presente Accordo 
                                fino alla data del 31 dicembre 2025 come stabilito nell’Allegato A.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Le Parti convengono che il corrispettivo come indicato al precedente punto 5.1, è omnicomprensivo ed adeguato al valore dell’Hardware, 
                        alla concessione in uso del Software ed all’erogazione dei Servizi ed è stato predeterminato per l’uso del Software e la fruizione dei 
                        Servizi per 3 (tre) anni a partire dalla sottoscrizione del presente Accordo in ragione della necessità del Fornitore di concedere in uso 
                        il Software per la durata indicata, unitamente alla vendita dell’Hardware nella fase di start-up della società e del business di riferimento. 
                    </li>
                    <li>
                        Pertanto, per tutta la durata della licenza d’uso di cui all’Allegato A del presente Accordo, 
                        il Cliente non dovrà alcun ulteriore corrispettivo al Fornitore per l’erogazione dei Servizi e l’uso del Software, 
                        salvo richieste di Servizi personalizzati non ricompresi nel presente Accordo, che il Fornitore potrà rifiutare ovvero quotare separatamente.
                    </li>
                    <li>
                        Le Parti espressamente stabiliscono che, alla data di scadenza del presente Accordo e del relativo Allegato A, 
                        come indicata nel successivo articolo 8, il medesimo Accordo e la licenza verranno tacitamente rinnovati con durata annuale e scadenza ad 
                        ogni 31 dicembre con rinnovo di anno in anno salvo disdetta da comunicarsi a mezzo PEC ovvero raccomandata con ricevuta di ritorno 
                        entro 30 (trenta) giorni dalla scadenza. In caso di rinnovo sarà dovuto dal Cliente al Fornitore il corrispettivo di Euro 350,00 (trecentocinquanta/00) per l’uso del Software e l’erogazione dei Servizi.
                    </li>
                    <li>
                        Il Fornitore si impegna ad emettere regolare fattura dei corrispettivi ricevuti dal Cliente.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 6 - Proprietà del Software
                </h4>
                <ol type="1">
                    <li>
                        Le Parti convengono espressamente che la titolarità di tutti i diritti patrimoniali di utilizzo e di commercializzazione del 
                        Software è esclusivamente in capo al Fornitore. 
                    </li>
                    <li>
                        Il Software è concesso in licenza d’uso dal Fornitore al Cliente per la durata stabilita nell’Allegato A, pari a tre anni, non esclusiva e non 
                        trasferibile a terzi secondo quanto meglio stabilito dal successivo articolo 8, dietro pagamento dei relativi corrispettivi stabiliti al 
                        precedente articolo 5.
                    </li>
                    <li>
                        Il Fornitore, in relazione al Software, potrà disporne in completa autonomia e senza necessità di preventiva autorizzazione del Cliente in ordine a sviluppi evoluzioni, correzioni e modifiche sul Software di cui è proprietaria. Il Fornitore avrà altresì la piena ed esclusiva 
                        titolarità dei diritti di uso e commercializzazione delle modifiche indicate al presente punto. 
                    </li>
                    <li>
                        Il Cliente non potrà in alcun caso ed in alcun modo utilizzare il Software per scopi diversi da quelli indicati nell’Accordo. In ogni caso il Cliente non potrà disporre e/o commercializzare e/o concedere in licenza d’uso od in sublicenza a soggetti terzi, compresi soggetti riferibili a vario titolo al Cliente medesimo, i prodotti di proprietà del Fornitore, da intendersi a tal fine il Software e 
                        qualsivoglia sviluppo, correzione, evoluzione, modifica ed aggiornamento del medesimo. 
                    </li>
                    <li>
                        Le Parti convengono espressamente che il Cliente potrà effettuare le attività di traduzione, adattamento, trasformazione, personalizzazione e correzione del Software, avvalendosi di personale dipendente e/o di personale collaboratore e/o di fornitori terzi di servizi di
                        manutenzione e/o assistenza -e/o sviluppo in seguito a:
                        <ol type="a">
                            <li>
                                messa in liquidazione, assoggettamento a fallimento o ad altra procedura concorsuale del Fornitore;
                            </li>
                            <li>
                                dismissione o cessazione dell’attività di impresa del Fornitore.
                            </li>
                        </ol>
                    </li>
                    <li>
                        In caso di utilizzo da parte del Cliente di personale dipendente e/o di personale collaboratore di fornitori terzi per servizi di manutenzione ed assistenza, lo stesso Cliente si 
                        impegnerà ad osservare i seguenti obblighi:
                        <ol type="a">
                            <li>
                                comunicare al Fornitore il nominativo del fornitore terzo;
                            </li>
                            <li>
                                far sottoscrivere a detti fornitori terzi una specifica dichiarazione mediante la quale quest’ultimi si impegnano:
                                <ol type="i">
                                    <li>
                                        a non violare in alcun modo i diritti di proprietà intellettuale, d’autore e lo specifico know how sul Software di esclusiva titolarità del Fornitore.  A tal fine, si precisa che i fornitori terzi dovranno espressamente impegnarsi a non utilizzare il know how e/o la conoscenza acquisiti nello svolgimento delle attività commissionate dal Cliente per la realizzazione, 
                                        a favore di soggetti ulteriori rispetto al Cliente, di prodotti analoghi a quelli del Fornitore;
                                    </li>
                                    <li>
                                        ad eseguire sul Software esclusivamente attività di traduzione, adattamento, trasformazione, personalizzazione e correzione per i fini e gli scopi richiesti di volta in volta dal Cliente e per utilità propria del medesimo, con acquisizione in capo al Cliente stesso di tutti gli eventuali diritti nascenti dalla prestazione d’opera di detti terzi, con esclusione di tali terzi da ogni e qualsivoglia titolo sui risultati 
                                        eventualmente raggiunti o conseguiti a seguito della prestazione dei servizi;
                                    </li>
                                    <li>
                                        a tenere comunque riservate le informazioni acquisite per il tramite dell’opera prestata presso il Cliente con riguardo al Software;
                                    </li>
                                </ol> 
                            </li>
                        </ol>
                    </li>
                    <li>
                        Resta inteso che il Cliente, qualora si avvalga, nelle ipotesi previste dal precedente punto 6.5, di fornitori terzi, si impegnerà a comunicare tempestivamente al Fornitore qualsiasi violazione dei diritti di proprietà intellettuale/industriale di quest’ultimo sul Software di cui venga a conoscenza. In tale ipotesi, così come nel caso in cui sia il Fornitore a comunicare al Cliente la violazione dei propri diritti di proprietà intellettuale e/o industriale sul Software, quest’ultima si impegnerà ad offrire al Fornitore la ragionevole collaborazione necessaria e richiesta ai fini della tutela dei diritti di proprietà intellettuale ed industriale e dello specifico know how sul Software, obbligandosi sin d’ora alla partecipazione 
                        alle azioni legali e/o giudiziarie che verranno intraprese dal Fornitore e alla condivisione delle relative spese.
                    </li>
                    <li>
                        Rimane inteso che, qualora si avvalga di personale dipendente e/o di personale collaboratore proprio ovvero di terzi per la realizzazione delle attività di traduzione, sviluppo, adattamento, trasformazione, personalizzazione e correzione dei Software Standard di cui al precedente punto 6.5, il Cliente dichiara che i risultati eventualmente raggiunti o conseguiti a seguito di dette attività saranno di esclusiva titolarità del Fornitore stesso e saranno soggetti ad un utilizzo esclusivo del Cliente medesimo e, altresì, che il personale dipendente e/o il personale collaboratore a vario titolo coinvolto in dette attività è già vincolato da obblighi di riservatezza in virtù dei contratti di collaborazione e/o di lavoro analoghi a quelli previsti per i fornitori terzi ai sensi del precedente punto 6.6, lettere a. e b..
                    </li>
                    <li>
                        Le Parti convengono che i diritti d’uso del Software sono stati ceduti al Cliente, in via non esclusiva, per tutto il territorio nazionale, e per l’intera durata di validità dei diritti sul Software medesimo, previo pagamento dei relativi corrispettivi. Le Parti dichiarano che il diritto di godimento del Software è da considerarsi non revocabile da parte del Fornitore salvo violazioni dell’Accordo e delle modalità d’uso convenute da parte del Cliente. Il Fornitore avrà diritto al risarcimento di eventuali danni per violazione da parte del Cliente degli obblighi a cui lo stesso è tenuto ai sensi del presente Accordo.
                    </li>
                    <li>
                        Il Fornitore riconosce che le banche dati del Cliente, così come tutte le informazioni, dati tecnici e/o commerciali in esse contenute delle quali verrà a conoscenza durante l’erogazione dei Servizi o durante l’uso del Software da parte del Cliente, sono di esclusiva proprietà del Cliente medesimo. Il Fornitore si impegna a non utilizzarne alcun elemento al di fuori di quanto previsto nell’Accordo, e/o comunque a non estrarre e/o reimpiegare il contenuto di tali banche dati e/o a non divulgare tale contenuto a soggetti terzi.
                    </li>
                    <li>
                        Il Fornitore si impegna espressamente a comunicare tempestivamente qualsiasi violazione del Software di cui venga a conoscenza e ad offrire al Cliente la ragionevole collaborazione necessaria e richiesta ai fini della tutela dello stesso. Analogamente, il Cliente si impegna espressamente a comunicare tempestivamente ogni eventuale notizia di tentativo di infiltrazioni nel Software ovvero tentativo di duplicazione, hacking, clonazione, sottrazione dei dati. Eventuali violazioni dovranno essere affrontate dalle Parti in collaborazione reciproca secondo il principio della buona fede nell’esecuzione del contratto.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 7 - Riservatezza
                </h4>
                <ol type="1">
                    <li>
                        Le Parti, per la durata dell’Accordo, potranno e/o dovranno condividere vicendevolmente certe informazioni tecniche, scientifiche, finanziarie, commerciali ed economiche. 
                    </li>
                    <li>
                        Il Cliente e/o il Responsabile del Cliente e/o qualsiasi terzo eventualmente autorizzato si impegnano a garantire la massima riservatezza della documentazione tecnica e/o di qualsiasi conoscenza, informazione, analisi, elaborazione, studio o documento che venga comunicato e/o fornito loro oralmente o in forma scritta da parte del Fornitore e/o degli incaricati e/o rappresentanti di quest’ultimo e/o di cui siano venuti a conoscenza personalmente.
                    </li>
                    <li>
                        Fermo quanto convenuto nel precedente punto 7.2, entrambe le Parti e/o qualsiasi soggetto a qualsiasi titolo operante per conto delle stesse saranno tenuti a: 
                        <ol type="a">
                            <li>
                                adottare misure di protezione idonee a mantenere ogni informazione, conoscenza e studio o documento strettamente riservato (adottando ogni misura all’uopo necessaria e/o opportuna); 
                            </li>
                            <li>
                                non propalarne il contenuto a terzi, salvo previa autorizzazione scritta dell’altra Parte; 
                            </li>
                            <li>
                                destinare ed usare ogni informazione, documento e/o conoscenza riservata esclusivamente allo svolgimento dei Servizi ed all’uso del Software;
                            </li>
                            <li>
                                non usarlo in modo da cagionare un danno e/o ledere direttamente o indirettamente gli interessi dell’altra Parte. 
                            </li>
                        </ol>
                    </li>
                    <li>
                        Gli obblighi di cui al presente articolo 7 restano validi anche in caso di cessazione degli effetti dell’Accordo a qualsiasi titolo. Il Cliente garantisce, assumendosene espressamente la responsabilità in caso di mancato adempimento, che i predetti obblighi verranno rispettati anche dai propri dipendenti, consulenti e collaboratori nonché dai propri aventi causa ovvero terzi a qualunque titolo coinvolti dal Cliente. 
                    </li>
                    <li>
                        Il Fornitore, nel caso di intervento tramite collegamento dedicato e diretto con il sistema informatico del Cliente, si obbliga ad adottare tutte le misure atte a garantire un’adeguata tutela dei dati dello stesso, assicurandone la totale riservatezza secondo quanto previsto al presente articolo.
                    </li>
                    <li>
                        Il Fornitore sarà tenuto a tenere indenne e manlevare il Cliente da qualsivoglia pretesa e/o danno subito e/o lamentato derivante dalla propria inosservanza del presente articolo 7.
                    </li>
                    <li>
                        Fermo quanto convenuto nel presente articolo, ognuna delle Parti rispettivamente, dietro semplice richiesta dell’altra Parte ed in qualsiasi momento, sarà tenuta alternativamente a: 
                        <ol type="a">
                            <li>
                                restituire tutto il materiale strettamente riservato ed ogni sua eventuale copia su qualunque supporto e/o formato della quale sia in possesso;  
                            </li>
                            <li>
                                distruggere qualunque copia del materiale strettamente riservato, su qualunque supporto e/o formato, della quale sia in possesso, ove la detenzione del medesimo non risulti più necessaria per consentire lo svolgimento dei Servizi.
                            </li>
                        </ol>
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 8 - Efficacia e Durata
                </h4>
                <ol type="1">
                    <li>
                        L’Accordo decorrerà dalla data di sottoscrizione e avrà durata triennale fino al 31 dicembre 2025, rinnovabile automaticamente di anno in anno con decorrenza 01 gennaio – 31 dicembre, salvo che vi sia disdetta da parte di una delle Parti che dovrà essere comunicata all’altra Parte a mezzo PEC ovvero raccomandata con ricevuta di ritorno entro 30 (trenta) giorni prima della data di scadenza di ogni anno contrattuale. 
                    </li>
                    <li>
                        Rimane espressamente inteso che le previsioni di cui all’articolo 6.5, 6.6, 6.7 e 6.8 rimarranno ferme in via definitiva anche alla cessazione naturale o anticipata dell’Accordo per qualsiasi causa verificatasi.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 9 - Recesso e Risoluzione
                </h4>
                <ol type="1">
                    <li>
                        Le Parti avranno la facoltà di esercitare il diritto di risolvere l’Accordo, fatto salvo il diritto di riscuotere le penali eventualmente definite nello stesso, mediante semplice comunicazione all’altra Parte a mezzo lettera raccomandata con ricevuta di ritorno ovvero a mezzo PEC, ove: 
                        <ol type="a">
                            <li>
                                una delle Parti venga posta in liquidazione, volontaria o giudiziale, e/o venga sottoposta a procedura fallimentare, concordato giudiziale, amministrazione controllata o straordinaria o ad altra procedura concorsuale; 
                            </li>
                            <li>
                                il Fornitore risulti inadempiente alle obbligazioni di cui agli articoli 4, 6 e 7;   
                            </li>
                            <li>
                                la Committente risulti inadempiente alle obbligazioni di cui agli articoli 5, 6 e 7.
                            </li>
                        </ol>
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 10 -  Limitazione di Responsabilità e penali contrattuali
                </h4>
                <ol type="1">
                    <li>
                        Le Parti concordano espressamente che, in caso di violazione da parte del Fornitore delle obbligazioni di cui ai precedenti articoli 4, 6 e 7, questi sarà tenuto al risarcimento del danno nella misura massima ed inderogabile del canone annuale d’uso del Software, pari ad Euro 350,00 (trecentocinquanta/00). 
                    </li>
                    <li>
                        Il Cliente, in ragione della tipologia dell’Accordo e della fase di attività di impresa del Fornitore, dichiara ed accetta che è escluso ogni risarcimento del danno eventualmente provocato dal Fornitore nell’esecuzione del presente Accordo ulteriore rispetto a quello di cui al precedente punto 10.1, salvo il caso di dolo o colpa grave da parte del Fornitore.
                    </li>
                    <li>
                        In caso di violazione degli obblighi di cui al presente Accordo da parte del Cliente, il Fornitore avrà diritto alla corresponsione di una penale di Euro 100 (cento) per ogni giorno di violazione e fino alla rimozione degli effetti della violazione da parte del Cliente. Resta fermo il diritto del Fornitore al risarcimento del maggior danno.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 11 -  Miscellanea
                </h4>
                <h6>
                    A. Esecuzione dell’Accordo
                </h6>
                <ol type="1">
                    <li>
                        Il Fornitore si impegna a garantire la continuità dei Servizi oggetto dell’Accordo. Il Fornitore eseguirà inoltre le attività oggetto dell’Accordo:
                        <ol type="a">
                            <li>
                                nel rispetto della normativa per tempo vigente e delle eventuali indicazioni delle Autorità;
                            </li>
                            <li>
                                in modo coerente con le finalità del Software e dell’uso convenuto con il Cliente;
                            </li>
                            <li>
                                sulla base dei parametri di “best practice” adottati dal Fornitore medesimo nell’erogazione dello stesso o analogo servizio ad altri clienti;
                            </li>
                            <li>
                                sotto la propria esclusiva responsabilità e a propria cura e rischio, quale imprenditore indipendente dotato di autonoma organizzazione.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Il Cliente si impegna a propria volta ad eseguire l’Accordo secondo correttezza e buona fede. In particolare il Cliente:
                        <ol type="a" >
                            <li>
                                predisporrà tutte le misure idonee a garantire la tutela delle proprietà intellettuali ed industriali del Fornitore, incluse tutte le misure atte a garantire il medesimo adempimento da parte di propri collaboratori ovvero fornitori terzi; 
                            </li>
                            <li>
                                predisporrà tutte le misure idonee a garantire la riservatezza su ogni informazione reperita dal Fornitore in esecuzione dell’Accordo, incluse tutte le misure atte a garantire il medesimo adempimento da parte di propri collaboratori ovvero fornitori terzi; 
                            </li>
                            <li>
                                collaborerà con il Fornitore in qualsiasi caso dovesse venire a conoscenza di tentativi di manomissione, duplicazione, sottrazione, falsificazione di documenti, informazioni, codici sorgenti ed in generale delle proprietà intellettuali ed industriali del Fornitore, assicurandosi l’analogo adempimento da parte di propri collaboratori ovvero fornitori terzi.
                            </li>
                        </ol> 
                    </li>
                </ol>
                <h6>
                    B. Sicurezza sul posto di lavoro
                </h6>
                <ol type="1" start="3">
                    <li>
                        Durante l’esecuzione delle attività oggetto dell’Accordo, le Parti si impegnano all’osservanza delle disposizioni normative in materia di sicurezza e salute sui luoghi di lavoro, di cui, in particolare, al d.lgs. 9 aprile 2008, n. 81, e successive modifiche ed integrazioni, e a cooperare all’attuazione delle misure di prevenzione e protezione dai rischi cui è esposto il personale. Le Parti faranno osservare scrupolosamente dal proprio personale tutte le disposizioni e le prescrizioni derivanti dalle leggi e dalle disposizioni vigenti o che potessero intervenire nel corso del rapporto relative alla prevenzione degli infortuni ed all’igiene sul lavoro, impegnandosi, tra l’altro, ad adottare, a proprie cura e spese, tutte le misure e i provvedimenti per l’adempimento delle predette prescrizioni. Le Parti dichiarano di essere stati informati sui rischi generici e specifici esistenti negli ambienti in cui andranno a prestare la propria attività e sulle misure di prevenzione ed emergenza adottate, nonché di aver ricevuto il documento unico per la valutazione rischi da interferenze (DUVRI).
                    </li>
                </ol>
                <h6>
                    C. Migrazione
                </h6>
                <ol type="1" start="4">
                    <li>
                        Ove richiesta dal Cliente, il Fornitore si impegnerà, al termine dell’Accordo, anche ove questo venga risolto o cessi di avere efficacia per motivi diversi dalla sua naturale scadenza, a supportare il Cliente nelle operazioni di migrazione verso altro sistema o piattaforma. Le spese per la migrazione saranno poste a carico del Cliente, verranno esposte separatamente dal Fornitore e non si considereranno in ogni caso incluse nel corrispettivo dell’Accordo. Il Cliente comunicherà al Fornitore 6 (sei) mesi prima della scadenza dell’Accordo, la richiesta di supporto al Fornitore che, in tal caso, dovrà prestare la sua opera per tale scopo. Il Fornitore non avrà alcun obbligo di collaborare con il Cliente alla migrazione verso altro sistema o piattaforma nel caso in cui non giunga alcuna richiesta dal Cliente entro il termine di cui sopra e, in ogni caso, qualora il Cliente – al momento della richiesta – si fosse resa gravemente inadempiente verso il Fornitore.  
                    </li>
                    <li>
                        In ogni caso il Cliente potrà richiedere assistenza per la migrazione dei dati per tutta la durata dell’Accordo ma, ove non rispetti il preavviso di cui al precedente punto 11.4, il Fornitore avrà facoltà di rifiutare la richiesta ovvero di richiedere il pagamento anticipato delle relative spese.  
                    </li>
                </ol>
                <h6>
                    D. Recesso
                </h6>
                <ol type="1" start="6">
                    <li>
                        In ragione della tipologia dell’Accordo e della fase di attività di impresa del Fornitore, le Parti espressamente convengono che è esclusa per entrambe la facoltà di recedere dall’Accordo per la durata del medesimo, come tale da intendersi il primo triennio con scadenza al 31 dicembre 2025, salvo motivi di giusta causa. 
                    </li>
                    <li>
                        In caso di rinnovo ai sensi dell’articolo 5 e dell’articolo 8 precedenti, il Cliente avrà facoltà di recedere in qualunque momento senza alcun diritto alla ripetizione di quanto già corrisposto in favore del Fornitore. In caso di recesso del Fornitore, questi sarà tenuto unicamente alla restituzione del canone annuale corrisposto dal Cliente per l’uso del Software detratto l’importo relativo al periodo di effettivo utilizzo del medesimo da parte del Cliente, calcolato in termini di mensilità.  
                    </li>
                </ol>
                <h6>
                    E. Garanzie ed impegni
                </h6>
                <ol type="1" start="8">
                    <li>
                        Per un periodo di 6 (sei) mesi, a partire dall’entrata in produzione di ogni nuova versione del Software di uno o più dei suoi moduli che siano stati realizzati dal Fornitore in esecuzione dell’Accordo, il Fornitore stesso si impegna a rimuovere, senza costi aggiuntivi, tutti i vizi che potrebbero sorgere.
                    </li>
                    <li>
                        Il Fornitore garantisce la conformità del Software alla sua relativa documentazione di volta in volta allegata.
                    </li>
                    <li>
                        Il Fornitore si impegna a mantenere nel suo catalogo il Software nella completezza dei suoi moduli e/o funzioni per un periodo di tempo pari alla durata dell’Accordo ossia pari a 3 (tre) anni a partire dalla data di efficacia dell’Accordo e per tutto il periodo di eventuale rinnovo già perfezionatosi. Decorso detto periodo, il Fornitore informerà con almeno 6 (sei) mesi di anticipo il Cliente della sua eventuale decisione di non più mantenere nel suo catalogo il Software, nella sua interezza o in parte. Rimane espressamente inteso tra le Parti che tale obbligo di mantenere a catalogo il Software decadrà nel momento in cui il Cliente escludesse il Fornitore dalla manutenzione e/o dall’assistenza sul Software stesso.
                    </li>
                    <li>
                        Il Fornitore garantisce e manleva il Cliente da ogni azione, reclamo, rivendicazione o opposizione da parte di chiunque invochi un diritto di proprietà intellettuale sul Software; in tal caso, i risarcimenti e le spese, di qualsiasi natura esse siano, sostenute dal Cliente per esercitare il diritto di difesa, comprese le spese legali, oltre che eventuali pronunce di risarcimento danno a suo carico, saranno sostenute dal Fornitore. Qualsiasi intervento sul Software a titolo di garanzie di conformità, contrattuale e/o di proprietà intellettuale deve comportare anche un intervento che conformi la relativa documentazione e l’identificazione esplicita di qualsiasi modifica, soppressione, aggiunta od altra modifica rispetto alla versione precedente.
                    </li>
                </ol>
                <h6>
                    F. Compliance
                </h6>
                <ol type="1" start="12">
                    <li>
                        Le Parti si impegnano ad effettuare ogni attività di loro pertinenza nel pieno rispetto della normativa vigente in materia di protezione dei dati personali. 
                    </li>
                    <li>
                        Il Fornitore si impegna a osservare le disposizioni eventualmente applicabili alla sua attività ai sensi della vigente normativa antiriciclaggio. 
                    </li>
                </ol>
                <h6>
                    G. Comunicazioni
                </h6>
                <ol type="1" start="14">
                    <li>
                        Nulla di quanto contenuto nell’Accordo potrà essere interpretato come costituzione di una joint venture, partnership o rapporto di lavoro subordinato tra le Parti, né ciascuna Parte sarà considerata capace di creare obbligazioni o cedere diritti in relazione all’Accordo senza previo consenso scritto dell’altra.
                    </li>
                    <li>
                        È fatto divieto espresso al Cliente di cedere i diritti di cui al presente Accordo ovvero di concedere sublicenze del Software senza previo consenso scritto del Fornitore.
                    </li>
                    <li>
                        Qualsiasi modifica, integrazione e/o rinuncia all’Accordo non sarà valida e vincolante ove non risulti da atto sottoscritto dalle Parti. 
                    </li>
                </ol>
                <h6>
                    I. Consegna
                </h6>
                <ol type="1" start="18">
                    <li>
                        In caso di conclusione dell’Accordo a qualsiasi titolo intervenuta, il Cliente si impegna a restituire entro 30 (trenta) giorni dall’eventuale cessazione tutto il materiale e/o la documentazione tecnica inerente al Software e consegnata allo stesso dal Fornitore, senza trattenerne alcuna copia. 
                    </li>
                </ol>
                <h6>
                    J. Subappalto
                </h6>
                <ol type="1" start="19">
                    <li>
                        Rimane espressamente inteso che il Fornitore potrà subappaltare le attività di cui all’Accordo o parte di esse a propria discrezione, previa semplice comunicazione scritta al Cliente. Il Fornitore, in ogni caso, sarà responsabile verso il Cliente per tutti gli obblighi derivanti dall’esecuzione delle attività predette, anche quando le stesse siano compiute dai suoi subappaltatori.
                    </li>
                </ol>
                <h6>
                    K. Legge applicabile e Foro competente
                </h6>
                <ol type="1" start="20">
                    <li>
                        La presente scrittura privata, così come qualsiasi futuro accordo ad essa collegato, è regolamentata dalla legge italiana.
                    </li>
                    <li>
                        Per qualsiasi controversia tra le parti inerente all’Accordo ed alla sua esecuzione, risoluzione ed interpretazione, sarà competente in via esclusiva il Foro di Roma. Resta espressamente convenuta l’esclusione di qualsivoglia foro alternativo.
                    </li>
                </ol>
                <h4 class="text-center">
                    Sottoscrizione
                </h4>
                <p>
                    Ai sensi e per gli effetti di cui agli art. 1341 e 1342 Cod. Civ., il Cliente, dopo averne presa attenta e specifica conoscenza e visione, accetta e sottoscrive espressamente le seguenti clausole:
                </p>
                <form>
                    <div class="form-check" v-for="cl,i in agree_clauses" :key="i">
                        <input type="checkbox" class="form-check-input" :id="`check-att-a-${i}`" v-model="cl.checked">
                        <label class="form-check-label" :for="`check-att-a-${i}`">
                            {{ cl.text }}
                        </label>
                    </div>
                </form>
            </div>
            <div class="col-12" v-else-if="status === 'READING_ATTACHMENT_A'">
                <h1 class="text-center">Allegato A (Contratto di Licenza)</h1>
                <p>
                    Con il presente contratto, il Cliente accetta le condizioni di utilizzo del software Adapta Easy, di proprietà esclusiva della società Vysio S.r.l., con sede legale in Casarano (LE), Via Carlo Magno n. 17, C.F. e P. IVA 05156040759 (d’ora in avanti anche il “Fornitore”).
                </p>
                <hr/>
                <h5 class="text-center">
                    premesso che
                </h5>
                <ol type="a">
                    <li>
                        il Fornitore è una società start-up innovativa attiva nell’ambito dello sviluppo di software per il settore ottico-optometristico; 
                    </li>
                    <li>
                        il Fornitore ha sviluppato il software Adapta Easy, che, in uno con gli appositi strumenti all’uopo progettati, consente agli utilizzatori di effettuare procedure optometriche finalizzate alla facilitazione dell’adattamento degli utenti alle lenti progressive;
                    </li>
                    <li>
                        il software Adapta Easy (d’ora in avanti denominato il “Software”) consiste nel programma di utilizzo dei dispositivi acquistati separatamente dal Cliente (d’ora in avanti tutti congiuntamente denominati l’“Hardware”), i quali comprendono: 
                        <ol type="1">
                            <li>
                                postazione fissa con schermo touchscreen 15,6 pollici per l’utilizzo da vicino del paziente e cassetto contenitore degli strumenti;
                            </li>
                            <li>
                                totem in plexiglass con schermo touchscreen 32 pollici;
                            </li>
                            <li>
                                strumenti specifici per l’esecuzione delle procedure optometriche;
                            </li>
                            <li>
                                scheda madre di sistema;
                            </li>
                        </ol>
                    </li>
                    <li>
                        il Fornitore ha stipulato con il Cliente un contratto di cessione dell’Hardware e di fornitura di appositi servizi sul Software che intende concedere in uso attraverso la presente licenza (d’ora in avanti anche l’“Accordo”);
                    </li>
                    <li>
                        la presente licenza d’uso costituisce allegato dell’Accordo, formandone parte integrante e sostanziale; 
                    </li>
                    <li>
                        ai fini dell’interpretazione della presente licenza, le Parti riconoscono la validità delle definizioni di cui all’Accordo;
                    </li>
                    <li>
                        il Fornitore garantisce la piena titolarità dei diritti esclusivi di proprietà industriale ed intellettuale del Software e il correlativo proprio diritto di distribuzione;
                    </li>
                    <li>
                        il Cliente, con il presente contratto, acquista il diritto all’utilizzo dei dispositivi e del programma di cui alle premesse precedenti, al fine di migliorare le prestazioni inerenti alla propria attività professionale ed aziendale.
                    </li>
                </ol>
                <hr/>
                <p>
                    Tutto ciò premesso, che costituisce parte integrante del presente contratto, si conviene quanto segue:
                </p>
                <h4 class="text-center my-2">
                    Articolo 1 -  Licenza d'uso
                </h4>
                <ol type="1">
                    <li>
                        Ai termini e alle condizioni che seguono il Fornitore si obbliga a concedere in licenza d'uso al Cliente il Software, in uno considerato con l’Hardware acquistato separatamente, che il Cliente dichiara di conoscere ed accettare e che è parte integrante della presente licenza.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 2 -  Versione
                </h4>
                <ol type="1">
                    <li>
                        Il Fornitore concede al Cliente l’utilizzo della versione del Software distribuita al momento dell’acquisto della presente licenza. 
                    </li>
                    <li>
                        In caso di sviluppo di funzionalità aggiuntive rispetto al Software concesso in uso con la presente licenza, il Fornitore avviserà il Cliente in modo da consentire al medesimo di acquistare la licenza d’uso delle nuove funzioni del Software.
                    </li>
                    <li>
                        Resta inteso che la presente licenza non fornisce alcun diritto al Cliente in ordine ad eventuali software aggiuntivi ovvero diversi rispetto a quello concesso in uso al momento della sottoscrizione del presente accordo.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 3 -  Durata
                </h4>
                <ol type="1">
                    <li>
                        La presente licenza ha durata triennale e perderà qualsivoglia efficacia a partire dal primo giorno successivo alla scadenza, fissata alla data del 31 dicembre 2025.
                    </li>
                    <li>
                        Il Fornitore informerà il Cliente con preavviso di 30 (trenta) giorni rispetto alla data di scadenza della licenza al fine di concordare l’eventuale rinnovo ovvero l’acquisto di diversa licenza d’uso.
                    </li>
                    <li>
                        In ogni caso, la licenza si rinnoverà tacitamente con durata annuale e decorrenza 01 gennaio – 31 dicembre in caso di mancata disdetta entro 30 (trenta) giorni rispetto alla scadenza di cui al precedente punto 3.1 da comunicarsi come stabilito nell’Accordo.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 4 -  Consegna
                </h4>
                <ol type="1">
                    <li>
                        Il Software è incorporato nei dispositivi consegnati al Cliente, il quale ha già ricevuto apposite credenziali rilasciate dal Fornitore, dopo il perfezionamento del pagamento della relativa fattura, secondo quanto indicato nella clausola successiva.
                    </li>
                    <li>
                        Il Software si considererà accettato dal Cliente al momento della sottoscrizione del presente contratto.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 5 -  Installazione
                </h4>
                <ol type="1">
                    <li>
                        Il processo di installazione avviene autonomamente da parte del Cliente all’accensione dei dispositivi consegnati dal Fornitore.
                    </li>
                    <li>
                        Il Software non potrà essere installato dal Cliente in altre postazioni ovvero attraverso dispositivi diversi da quelli appositamente consegnati dal Fornitore mediante il contratto di acquisto dell’Hardware come individuato nelle premesse della presente licenza.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 6 -  Assistenza
                </h4>
                <ol type="1">
                    <li>
                        Al Cliente viene fornito il manuale di uso ed istruzioni del Software in versione digitale ed il Fornitore garantisce il servizio di help desk successivo erogato su contatto e-mail, mediante assistenza in remoto e telefonica, come meglio indicato nell’Accordo. 
                    </li>
                    <li>
                        Il manuale fornito, così come la formazione di cui al successivo punto 3, riguarda unicamente l’illustrazione delle modalità d’uso del Software, non comprendendo in modo pianificato alcun elemento riguardante la normativa sul trattamento dei dati personali.
                    </li>
                    <li>
                        Al Cliente che dovesse acquistare la licenza del Software per un numero minimo di cinque propri esercizi commerciali diversi, il Fornitore fornirà gratuitamente il servizio di formazione specializzata presso i locali del Cliente medesimo, di concerto con quest’ultimo per l’organizzazione della sessione di formazione. 
                    </li>
                    <li>
                        In ogni caso, il Fornitore potrà a sua insindacabile discrezionalità offrire il servizio di formazione all’uso del Software nelle modalità e nelle tempistiche decise dal Fornitore medesimo.
                    </li>
                    <li>
                        Resta salva la possibilità per ogni Cliente di richiedere separatamente e con apposito prezzo il servizio di formazione specializzata pressi i propri locali al prezzo indicato dal Fornitore.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 7 -  Aggiornamenti
                </h4>
                <ol type="1">
                    <li>
                        Gli aggiornamenti del Software di cui al successivo articolo 11 saranno resi disponibili al Cliente mediante apposito download cui accedere tramite credenziali rilasciate dal Fornitore.
                    </li>
                    <li>
                        La disponibilità di un aggiornamento sarà notificata al Cliente mediante invio di una mail alla casella di posta elettronica dallo stesso indicata.
                    </li>
                    <li>
                        Per l’aggiornamento del Software valgono le condizioni indicate all’articolo 11.
                    </li>
                    <li>
                        Resta inteso che, ai sensi e per gli effetti della presente licenza ed ai fini del presente articolo e del successivo punto 12.1, si intendono quali aggiornamenti le migliorie di sistema eventualmente apportate dal Fornitore per l’ordinaria operatività del Software, restando invece esclusi tutti gli aggiornamenti contenenti moduli ovvero servizi diversi e nuovi rispetto a quelli installati con il Software iniziale come definito nel precedente articolo 2. 
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 8 -  Utilizzazione dei programmi
                </h4>
                <ol type="1">
                    <li>
                        Il Cliente ha diritto di usare il Software esclusivamente nell’ambito della propria azienda ed unicamente attraverso i dispositivi consegnatigli dal Fornitore. 
                    </li>
                    <li>
                        È fatto espresso divieto al Cliente di copiare il Software, in tutto o in parte, salvo nel caso in cui si sia reso necessario per ragioni di sicurezza e a causa del rischio di perdita dei dati. In questo caso il Cliente si obbliga a comunicare la circostanza al Fornitore ed a concordare con quest’ultimo le modalità di ripristino del Software originale e di cancellazione della copia eventualmente effettuata. 
                    </li>
                    <li>
                        Le modalità e regole di utilizzo e i diritti e doveri dell’utilizzatore sono definite agli articoli da 64-bis a 64-quater della legge sul diritto d’autore (legge 22 aprile 1941 n. 633) oltre che da quanto stabilito nell’Accordo.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 9 -  Proprietà e divieto di cessione
                </h4>
                <ol type="1">
                    <li>
                        Il Software rimane di esclusiva proprietà del Fornitore titolare e distribuito in esclusiva dal medesimo ovvero da suoi intermediari o rappresentanti. È fatto quindi espresso divieto al Cliente di distribuire a terzi od al pubblico il Software in uno con l’Hardware consegnato per l’utilizzo del medesimo ovvero di cederli o concederli in sublicenza a terzi o, comunque, di consentire l'uso da parte di terzi sia a titolo gratuito che a titolo oneroso. 
                    </li>
                    <li>
                        In ogni caso, vale quanto stabilito dall’art. 6 dell’Accordo. 
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 10 -  Segreto e Modifiche
                </h4>
                <ol type="1">
                    <li>
                        Il Cliente si obbliga a mantenere segreto il contenuto del Software ed a proteggere i diritti del Fornitore titolare; in particolare, fatto salvo quanto indicato alla clausola di cui al precedente articolo 8.2, il Cliente si obbliga a non copiare ovvero modificare il Software in qualunque forma potenzialmente idonea a replicarne od alternarne i codici sorgenti ed il contenuto consentendone la replicabilità e l’utilizzo tramite dispositivi diversi da quelli consegnati dal Fornitore ovvero consentendone l’utilizzo a terzi.
                    </li>
                    <li>
                        In ogni caso, vale quanto stabilito dagli articoli 6 e 7 dell’Accordo.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 11 -  Garanzia e responsabilità
                </h4>
                <ol type="1">
                    <li>
                        Il Fornitore garantisce esclusivamente che il Software è in grado di eseguire le operazioni previste e indicate nelle descrizioni tecniche relative all’applicativo che il Cliente dichiara di conoscere ed aver già accettato al momento del pagamento del corrispettivo. 
                    </li>
                    <li>
                        La garanzia è, peraltro, condizionata al corretto funzionamento dell'elaboratore e del software di sistema ed al corretto uso del sistema da parte del Cliente, nonché dall’integrità dell’applicativo licenziato. 
                    </li>
                    <li>
                        Il Fornitore si impegna per un periodo di durata della presente licenza a conservare la regolare operatività del Software, garantendo i necessari aggiornamenti gratuiti eventualmente resi necessari da malfunzionamenti originari.
                    </li>
                    <li>
                        A tal fine durante detto periodo il Fornitore provvederà, tempestivamente, al rilascio delle release dell’applicativo, mediante la modalità di cui al precedente articolo 7.
                    </li>
                    <li>
                        Il Fornitore garantisce l’idoneità e funzionalità del Software per le finalità conosciute ed accettate dal Cliente. Il Fornitore non risponde dei danni diretti o indiretti e delle contestazioni comunque subite dal Cliente o da terzi in dipendenza dall'uso non corretto del Software, fatto salvo quanto inderogabilmente previsto dalla legge.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 12 -  Assistenza e/o consulenza
                </h4>
                <ol type="1">
                    <li>
                        Al di fuori del periodo di fornitura degli aggiornamenti gratuiti come qualificati ai sensi dei precedenti articoli 2 e 7, e della garanzia da malfunzionamenti pari ad un anno dalla messa a disposizione dell’applicativo, a richiesta del Cliente, il Fornitore si dichiara disponibile a prestare, previa stipulazione di appositi separati contratti, l'assistenza e/o consulenza necessaria per la manutenzione o aggiornamento o personalizzazione o implementazione del Software, nonché per l'eventuale addestramento del personale del Cliente incaricato dell’uso del Software. 
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 13 - Obblighi delle Parti
                </h4>
                <ol type="1">
                    <li>
                        Il Cliente si obbliga ad eseguire la presente scrittura privata secondo diligenza e buona fede;
                    </li>
                    <li>
                        Il Cliente si impegna a non rivendicare alcun diritto né a depositare domande di registrazione di alcun titolo di proprietà intellettuale, a suo nome o a mezzo di terzi, interferente con il design. Tuttavia, un'eccezione a tale obbligo è prevista nel solo caso in cui Il Cliente ritenga che vi sia una situazione di urgenza per la quale non è in grado di ottenere l'autorizzazione del Fornitore. In questo unico caso, il Cliente può registrare il diritto di proprietà intellettuale a condizione che tale registrazione sia effettuata per conto del Fornitore. In tal caso, il Cliente deve immediatamente comunicare al Fornitore l'avvenuto deposito della domanda di registrazione del diritto di proprietà intellettuale e il Cliente deve necessariamente seguire le istruzioni del Fornitore in merito al trasferimento immediato della domanda di registrazione a quest'ultimo o al ritiro immediato della domanda depositata, a seconda delle indicazioni fornite dal Fornitore. Il mancato trasferimento del titolo di proprietà o il suo ritiro nel territorio di interesse costituirà una grave violazione e comporterà l'immediata risoluzione della presente scrittura privata.  
                    </li>
                    <li>
                        Il Cliente si impegna a non commercializzare, produrre o promuovere, direttamente o indirettamente, prodotti in contraffazione con il Software. 
                    </li>
                    <li>
                        Il Cliente si impegna a non commercializzare, produrre o promuovere, direttamente o indirettamente, prodotti affini al Software Adapta Easy ad esclusione di quelli espressamente autorizzati dal Fornitore.
                    </li>
                    <li>
                        Il Cliente si impegna a non cedere ad alcun terzo senza l’autorizzazione scritta del Fornitore la presente scrittura privata anche secondo quanto stabilito nell’Accordo.
                    </li>
                    <li>
                        Le Parti reciprocamente si impegnano a promuovere le rispettive attività sui propri canali di promozione e marketing. Rispettivamente, il Cliente si impegna a comunicare l’utilizzo del Software ai fini dello svolgimento della propria attività professionale ed il Fornitore si impegna ad inserire il Cliente nel proprio porfolio.
                    </li>
                    <li>
                        Il Fornitore si impegna a conservare il Software nel proprio catalogo almeno per la durata della presente licenza e come meglio stabilito all’articolo 11 dell’Accordo.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 14 - Corrispettivo
                </h4>
                <ol type="1">
                    <li>
                        Il corrispettivo per l’uso del Software è regolato dall’articolo 5 dell’Accordo.
                    </li>
                </ol>
                <h4 class="text-center my-2">
                    Articolo 15 - Legge applicabile e Foro competente
                </h4>
                <ol type="1">
                    <li>
                        La presente scrittura privata, così come qualsiasi futuro accordo ad essa collegato, è regolamentata dalla legge italiana.
                    </li>
                    <li>
                        Le Parti in ogni caso riconoscono e convengono che tutto quanto non espressamente regolato dalla presente licenza è regolato dall’Accordo, di cui essa costituisce parte integrale e sostanziale.
                    </li>
                    <li>
                        In caso di contrasto tra disposizioni dell’Accordo e della presente licenza, varranno le prime.
                    </li>
                    <li>
                        Per qualsiasi controversia tra le parti inerente all’Accordo ed alla sua esecuzione, risoluzione ed interpretazione, sarà competente in via esclusiva il Foro di Roma. Resta espressamente convenuta l’esclusione di qualsivoglia foro alternativo.
                    </li>
                </ol>
                <h4 class="text-center">
                    Sottoscrizione delle specifiche clausole
                </h4>
                <p>
                    Ai sensi e per gli effetti di cui agli art. 1341 e 1342 Cod. Civ., il Cliente, dopo averne presa attenta e specifica conoscenza e visione, accetta e sottoscrive espressamente le seguenti clausole:
                </p>
                <form>
                    <div class="form-check" v-for="cl,i in att_a_clauses" :key="i">
                        <input type="checkbox" class="form-check-input" :id="`check-att-a-${i}`" v-model="cl.checked">
                        <label class="form-check-label" :for="`check-att-a-${i}`">
                            {{ cl.text }}
                        </label>
                    </div>
                </form>
            </div>
            <div class="col-12 text-center">
                <hr/>
                <button class="btn btn-primary" @click="onDealAccepted()" v-if="status ==='READING_LICENSE'"  :disabled="!agree_clauses.every(cl => cl.checked === true)">
                    Accetta l'accordo e procedi
                </button>
                <button class="btn btn-primary" @click="onLicenseAccepted()" v-else-if="status ==='READING_ATTACHMENT_A'" :disabled="!att_a_clauses.every(cl => cl.checked === true)">
                    Accetta l'Allegato A e procedi
                </button>
            </div>
        </div>
        <div class="row" v-else-if="status === 'ACCEPTED_OK'">
            <div class="col-12">
                <div class="alert alert-success">
                    <h3>Licenza accettata!</h3>
                    <p>
                        Grazie per aver accettato i termini di licenza. Ora puoi utilizzare il sistema Adapta Easy!
                    </p>
                </div>
            </div>
            <div class="col-12">
                <router-link class="btn btn-primary" to="/auth">Procedi</router-link>
            </div>
        </div>
        <div class="row" v-else-if="status === 'ACCEPTED_ERROR'">
            <div class="col-12">
                <div class="alert alert-danger">
                    <h3>Errore!</h3>
                    <p>
                        Lorem ipsum
                    </p>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-primary" @click="retryOnError()">
                    Riprova
                </button>
            </div>
        </div>
    </GeneralTermsLayout>    
</template>

<script>
import GeneralTermsLayout from '@/views/layouts/general-terms.vue';
import API from '@/api.js';

export default {
    name: 'LicenseTerms',
    components: {
        GeneralTermsLayout,
    },
    data() {
        return {
            user: this.$auth.user(),
            store: this.$auth.user().store,
            status: this.$auth.user().store.license_terms_accepted === false ? 'READING_LICENSE' : 'ACCEPTED_OK',
            agree_clauses: [
                {
                    checked: false,
                    text: 'Art. 4 (Prestazione dei Servizi) – punti 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9;',
                },
                {
                    checked: false,
                    text: 'Art. 5 (Corrispettivo) – punti 5.1, 5.2, 5.3, 5.4;',
                },
                {
                    checked: false,
                    text: 'Art. 6 (Proprietà del Software) – punti 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 6.10, 6.11;',
                },
                {
                    checked: false,
                    text: 'Art. 7 (Riservatezza) – punti 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7;',
                },
                {
                    checked: false,
                    text: 'Art. 8 (Efficacia e durata) – punti 8.1, 8.2;',
                },
                {
                    checked: false,
                    text: 'Art. 9 (Recesso e Risoluzione) – punto 9.1;',
                },
                {
                    checked: false,
                    text: 'Art. 10 (Limitazione di responsabilità e penali contrattuali) – punti 10.1, 10.2, 10.3;',
                },
                {
                    checked: false,
                    text: 'Art. 11 (Miscellanea) – punti 11.2, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 11.10, 11.11, 11.14, 11.15, 11.16, 11.17, 11.18, 11.20, 11.21.',
                }
            ],
            att_a_clauses: [
                {
                    checked: false,
                    text: 'Art. 2 (Versione) – punti 2.1, 2.2, 2.3;',
                },
                {
                    checked: false,
                    text: 'Art. 3 (Durata) – punti 3.1, 3.2, 3.3;',
                },
                {
                    checked: false,
                    text: 'Art. 4 (Consegna) – punto 4.2;',
                },
                {
                    checked: false,
                    text: 'Art. 5 (Installazione) – punto 5.2;',
                },
                {
                    checked: false,
                    text: 'Art. 6 (Assistenza) – punti 6.1, 6.2, 6.3, 6.4, 6.5;',
                },
                {
                    checked: false,
                    text: 'Art. 7 (Aggiornamenti) – punti 7.1, 7.2, 7.3, 7.4;',
                },
                {
                    checked: false,
                    text: 'Art. 8 (Utilizzazione dei programmi) – punti 8.1, 8.2, 8.3;',
                },
                {
                    checked: false,
                    text: 'Art. 9 (Proprietà e divieto di cessione) – punti 9.1, 9.2;',
                },
                {
                    checked: false,
                    text: 'Art. 10 (Segreto e Modifiche) – punti 10.1, 10.2;',
                },
                {
                    checked: false,
                    text: 'Art. 11 (Garanzia e Responsabilità) – punti 11.2, 11.3, 11.4, 11.5;',
                },
                {
                    checked: false,
                    text: 'Art. 12 (Assistenza e/o consulenza) – punto 12.1;',
                },
                {
                    checked: false,
                    text: 'Art. 13 (Obblighi delle parti) – punti 13.1, 13.2, 13.3, 13.4, 13.5, 13.6, 13.7;',
                },
                {
                    checked: false,
                    text: 'Art. 15 (Legge applicabile e Foro competente) – punti 15.1, 15.2, 15.3, 15.4',
                },
            ]
            // status: 'READING_ATTACHMENT_A',
        }
    },
    methods: {
        onDealAccepted() {
            // document.getElementById('top').scrollIntoView();
            // window.scrollTo(0,0);
            this.status='READING_ATTACHMENT_A';
        },
        async onLicenseAccepted() {
            // Accept license and proceed
            try {
                const r = await this.axios.put(API.acceptLicense.path(this.user.store.id), {
                    license_terms_accepted: true,
                });
                if( r.status === 200) {
                    await this.$auth.fetchUser();
                    console.log(await this.$auth.fetchUser())
                    this.status = 'ACCEPTED_OK';
                } else {
                    this.status = 'ACCEPTED_ERROR';
                }
            } catch (e) {
                this.status = 'ACCEPTED_ERROR';
            }
        },
        retryOnError() {
            this.status = 'READING_LICENSE';
        },
    },
}
</script>

<style scoped>


</style>

<template>
    <div class="container-fluid">
        <div class="row bg-primary">
            <div class="col-8 mx-auto py-3">
                <img src="/assets/imgs/ae-logo-w-horizontal.png" height="70" />
            </div>
        </div>
        <div class="row">
            <div class="col-8 mx-auto py-4">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GeneralTermsLayout',
};
</script>
